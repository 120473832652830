var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"swiperBox"},[_c('div',{staticClass:"incontentBox"},[_c('div',{staticClass:"showBox",style:({'margin-left': _vm.num * 900 + 'px','transition': 'all .2s ease-out .1s',})},[_vm._m(0),_vm._m(1)])]),_c('div',{staticClass:"btnBox"},[_c('div',{staticClass:"inBtnBox"},[_c('div',{on:{"click":_vm.prev}},[(this.num != 0)?_c('img',{attrs:{"src":"https://www.yyayit.com/oss/official/pc/left.png","alt":""}}):_vm._e()]),_c('div',{on:{"click":_vm.next}},[(this.num != -1)?_c('img',{attrs:{"src":"https://www.yyayit.com/oss/official/pc/right.png","alt":""}}):_vm._e()])])])]),_c('div',{staticClass:"mSwiperBox"},[_c('div',{staticClass:"contentBox"},[_c('div',{staticClass:"incontentBox"},[_c('div',{staticClass:"showBox",style:({'margin-left': _vm.num * 220 + 'px','transition': 'all .2s ease-out .1s',})},[_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7)])])]),_c('div',{staticClass:"btnBox"},[_c('div',{staticClass:"inBtnBox"},[_c('div',{on:{"click":_vm.prev}},[(this.num != 0)?_c('img',{attrs:{"src":"https://www.yyayit.com/oss/official/pc/left.png","alt":""}}):_vm._e()]),_c('div',{on:{"click":_vm.next}},[(this.num != -5)?_c('img',{attrs:{"src":"https://www.yyayit.com/oss/official/pc/right.png","alt":""}}):_vm._e()])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"showItme"},[_c('div',{staticClass:"itmeConten"},[_c('div',{staticClass:"itmeName"},[_vm._v("为中国留学生扫清语言障碍")]),_c('div',{staticClass:"itmeImg"},[_c('img',{attrs:{"src":"https://www.yyayit.com/oss/official/pc/introduction1.png","alt":""}})])]),_c('div',{staticClass:"itmeConten"},[_c('div',{staticClass:"itmeName"},[_vm._v("同声传译：高准确率和领先的语音播报技术，无限接近真人同传体验。")]),_c('div',{staticClass:"itmeImg"},[_c('img',{attrs:{"src":"https://www.yyayit.com/oss/official/pc/introduction2.png","alt":""}})])]),_c('div',{staticClass:"itmeConten"},[_c('div',{staticClass:"itmeName"},[_vm._v("浮窗（同传）：听网课，看音视频。")]),_c('div',{staticClass:"itmeImg"},[_c('img',{attrs:{"src":"https://www.yyayit.com/oss/official/pc/introduction3.png","alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"showItme"},[_c('div',{staticClass:"itmeConten"},[_c('div',{staticClass:"itmeName"},[_vm._v("交替翻译：两种语言互译，交流更方。")]),_c('div',{staticClass:"itmeImg"},[_c('img',{attrs:{"src":"https://www.yyayit.com/oss/official/pc/introduction4.png","alt":""}})])]),_c('div',{staticClass:"itmeConten"},[_c('div',{staticClass:"itmeName"},[_vm._v("支持100+种语言，即使在海外也可以流畅的翻译。")]),_c('div',{staticClass:"itmeImg"},[_c('img',{attrs:{"src":"https://www.yyayit.com/oss/official/pc/introduction5.png","alt":""}})])]),_c('div',{staticClass:"itmeConten"},[_c('div',{staticClass:"itmeName"},[_vm._v("历史记录自动保存在本地，方便回看和查找。")]),_c('div',{staticClass:"itmeImg"},[_c('img',{attrs:{"src":"https://www.yyayit.com/oss/official/pc/introduction6.png","alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"showItme"},[_c('div',{staticClass:"itmeConten"},[_c('div',{staticClass:"itmeName"},[_vm._v("为中国留学生扫清语言障碍")]),_c('div',{staticClass:"itmeImg"},[_c('img',{attrs:{"src":"https://www.yyayit.com/oss/official/pc/introduction1.png","alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"showItme"},[_c('div',{staticClass:"itmeConten"},[_c('div',{staticClass:"itmeName"},[_vm._v("同声传译：高准确率和领先的语音播报技术，无限接近真人同传体验。")]),_c('div',{staticClass:"itmeImg"},[_c('img',{attrs:{"src":"https://www.yyayit.com/oss/official/pc/introduction2.png","alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"showItme"},[_c('div',{staticClass:"itmeConten"},[_c('div',{staticClass:"itmeName"},[_vm._v("浮窗（同传）：听网课，看音视频。")]),_c('div',{staticClass:"itmeImg"},[_c('img',{attrs:{"src":"https://www.yyayit.com/oss/official/pc/introduction3.png","alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"showItme"},[_c('div',{staticClass:"itmeConten"},[_c('div',{staticClass:"itmeName"},[_vm._v("交替翻译：两种语言互译，交流更方。")]),_c('div',{staticClass:"itmeImg"},[_c('img',{attrs:{"src":"https://www.yyayit.com/oss/official/pc/introduction4.png","alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"showItme"},[_c('div',{staticClass:"itmeConten"},[_c('div',{staticClass:"itmeName"},[_vm._v("支持100+种语言，即使在海外也可以流畅的翻译。")]),_c('div',{staticClass:"itmeImg"},[_c('img',{attrs:{"src":"https://www.yyayit.com/oss/official/pc/introduction5.png","alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"showItme"},[_c('div',{staticClass:"itmeConten"},[_c('div',{staticClass:"itmeName"},[_vm._v("历史记录自动保存在本地，方便回看和查找。")]),_c('div',{staticClass:"itmeImg"},[_c('img',{attrs:{"src":"https://www.yyayit.com/oss/official/pc/introduction6.png","alt":""}})])])])
}]

export { render, staticRenderFns }