<template>
	<div>
		<div class="swiperBox">
			<div class="incontentBox">
				<div class="showBox" :style="{'margin-left': num * 900 + 'px','transition': 'all .2s ease-out .1s',}">
					<div class="showItme">
						<div class="itmeConten">
							<div class="itmeName">为中国留学生扫清语言障碍</div>
							<div class="itmeImg">
								<img  src="https://www.yyayit.com/oss/official/pc/introduction1.png" alt="">
							</div>
						</div>
						<div class="itmeConten">
							<div class="itmeName">同声传译：高准确率和领先的语音播报技术，无限接近真人同传体验。</div>
							<div class="itmeImg">
								<img  src="https://www.yyayit.com/oss/official/pc/introduction2.png" alt="">
							</div>
						</div>
						<div class="itmeConten">
							<div class="itmeName">浮窗（同传）：听网课，看音视频。</div>
							<div class="itmeImg">
								<img  src="https://www.yyayit.com/oss/official/pc/introduction3.png" alt="">
							</div>
						</div>
					</div>
					<!-- 二 -->
					<div class="showItme">
						<div class="itmeConten">
							<div class="itmeName">交替翻译：两种语言互译，交流更方。</div>
							<div class="itmeImg">
								<img  src="https://www.yyayit.com/oss/official/pc/introduction4.png" alt="">
							</div>
						</div>
						<div class="itmeConten">
							<div class="itmeName">支持100+种语言，即使在海外也可以流畅的翻译。</div>
							<div class="itmeImg">
								<img  src="https://www.yyayit.com/oss/official/pc/introduction5.png" alt="">
							</div>
						</div>
						<div class="itmeConten">
							<div class="itmeName">历史记录自动保存在本地，方便回看和查找。</div>
							<div class="itmeImg">
								<img src="https://www.yyayit.com/oss/official/pc/introduction6.png" alt="">
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="btnBox">
				<div class="inBtnBox">
					<div @click="prev"><img v-if="this.num != 0" src="https://www.yyayit.com/oss/official/pc/left.png" alt=""></div>
					<div @click="next"><img v-if="this.num != -1" src="https://www.yyayit.com/oss/official/pc/right.png" alt=""></div>
				</div>
			</div>
		</div>
		<div class="mSwiperBox">
			<div class="contentBox">
				<div class="incontentBox">
					<div class="showBox" :style="{'margin-left': num * 220 + 'px','transition': 'all .2s ease-out .1s',}">
						<div class="showItme">
							<div class="itmeConten">
								<div class="itmeName">为中国留学生扫清语言障碍</div>
								<div class="itmeImg">
									<img  src="https://www.yyayit.com/oss/official/pc/introduction1.png" alt="">
								</div>
							</div>
						</div>
						<!-- 二 -->
						<div class="showItme">
							<div class="itmeConten">
								<div class="itmeName">同声传译：高准确率和领先的语音播报技术，无限接近真人同传体验。</div>
								<div class="itmeImg">
									<img  src="https://www.yyayit.com/oss/official/pc/introduction2.png" alt="">
								</div>
							</div>
						</div>
						<!-- 三 -->
						<div class="showItme">
							<div class="itmeConten">
								<div class="itmeName">浮窗（同传）：听网课，看音视频。</div>
								<div class="itmeImg">
									<img  src="https://www.yyayit.com/oss/official/pc/introduction3.png" alt="">
								</div>
							</div>
						</div>
						<!-- 四 -->
						<div class="showItme">
							<div class="itmeConten">
								<div class="itmeName">交替翻译：两种语言互译，交流更方。</div>
								<div class="itmeImg">
									<img  src="https://www.yyayit.com/oss/official/pc/introduction4.png" alt="">
								</div>
							</div>
						</div>
						<!-- 五 -->
						<div class="showItme">
							<div class="itmeConten">
								<div class="itmeName">支持100+种语言，即使在海外也可以流畅的翻译。</div>
								<div class="itmeImg">
									<img  src="https://www.yyayit.com/oss/official/pc/introduction5.png" alt="">
								</div>
							</div>
						</div>
						<!-- 六 -->
						<div class="showItme">
							<div class="itmeConten">
								<div class="itmeName">历史记录自动保存在本地，方便回看和查找。</div>
								<div class="itmeImg">
									<img  src="https://www.yyayit.com/oss/official/pc/introduction6.png" alt="">
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="btnBox">
				<div class="inBtnBox">
					<div @click="prev"><img v-if="this.num != 0" src="https://www.yyayit.com/oss/official/pc/left.png" alt=""></div>
					<div @click="next"><img v-if="this.num != -5" src="https://www.yyayit.com/oss/official/pc/right.png" alt=""></div>
				</div>
			</div>
		</div>
	</div>
</template> 
<script>
export default {
  name: "Swiper",
  data(){
    return {
      num: 0,
      aa: ['1','2','3','4','5','6','7','8','9'],
    }
  },
  methods: {
    prev(){
      if (this.num < 0) {
        this.num += 1;
		console.log(this.num, 99)
      }
    },
    next(){
      if (this.num > -(this.aa.length + this.num)) {
        this.num -= 1;
      }else if(this.num < -(this.aa.length + this.num) && -(this.aa.length + this.num) != -0){
        this.num -= 1;
      }
	  console.log(this.num, 88)
    }
  },
}
</script>

<style lang="scss" scoped>
/* PC显示器 */
@media(min-width:690px){
	.mSwiperBox{
		display: none;
	}
	.swiperBox{
			position: relative;
			height: 650px;
			background-image: url('https://www.yyayit.com/oss/official/pc/videoBackground.png');
			background-position: center;
			background-size:cover;
			background-repeat: no-repeat;
			.incontentBox{
				width: 900px;
				margin:0 auto;
				height: 600px;
				overflow: hidden;
				.showBox{
					display: flex;
					width: 1800px;
					height: 600px;
						.showItme{
							width: 900px;
							text-align: center;
							padding-top:40px;
							height: 600px;
							margin-right: 10px;
							display: flex;
							color:#fff;
							.itmeConten{
								.itmeName{
									height: 50px;
									width: 260px;
									text-align: center;
									margin:10px auto 0;
								}
								.itmeImg{
									img{
										width: 295px;
										height: 510px;
									}
								}

							}

						}
				}

			}
			.btnBox{
				position: absolute;
				top:300px;
				width: 100%;
				.inBtnBox{
					width: 1000px;
					justify-content: space-between;
					display: flex;
					margin:0 auto;
					img{
						width: 50px;
						height: 50px;
					}
				}
			}
	}

}
/* 手机 */
@media(max-width:690px){
	.swiperBox {
		display: none;
	}
	.mSwiperBox{
			position: relative;
			height: 500px;
			background-image: url('https://www.yyayit.com/oss/official/mobile/videoBackground.png');
			background-position: center;
			background-size:cover;
			background-repeat: no-repeat;
			.contentBox{
				width: 100%;
				height: 450px;
				.incontentBox{
					width: 220px;
					margin:0 auto;
					height: 450px;
					overflow: hidden;
					.showBox{
						display: flex;
						width: 1800px;
						height: 450px;
							.showItme{
								width: 220px;
								text-align: center;
								padding-top:40px;
								height: 450px;
								display: flex;
								color:#fff;
								.itmeConten{
									.itmeName{
										height: 52px;
										width: 220px;
										font-size: 15px;
										text-align: center;
										margin:0 auto;
									}
									.itmeImg{
										img{
											width: 220px;
											height: 370px;
										}
									}

								}

							}
					}

				}
			}

			.btnBox{
				position: absolute;
				top:200px;
				width: 100%;
				.inBtnBox{
					width: 90%;
					justify-content: space-between;
					display: flex;
					margin:0 auto;
					img{
						width: 35px;
						height: 35px;
					}
				}
			}
	}
}

</style>
