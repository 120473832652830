<template>
  <div class="indexBox">
    <div class="brick"></div>
    <!-- 背景 -->
    <div class="firstBox">
        <div class="intitleBox">
          <div class="titleY">CONTACT US</div>
          <div class="titleZ">联系我们</div>
          <div class="titleL"></div>
        </div>
    </div>
    <div class="emailWxBox">
				<div class="inWxEmail">
					<!-- <div class="wxleft">
            <div class="wximg">
              <img src="https://www.yyayit.com/oss/official/pc/weChat.png" alt="">
            </div>
            <div class="wxname">微信号: xxxxzxxxxzxxxxz</div>
					</div> -->
					<div class="rightEmail">
            <div class="emailimg">
              <img src="https://www.yyayit.com/oss/official/pc/mail.png" alt="">
            </div>
            <div class="emailname">邮箱: Smart@yyayit.com</div>
					</div>
				</div>
    </div>
    <div class="footBox">
      <div>版权所有安徽译呀译信息科技有限公司，保留一切权力</div>
      <div @click="goFilings()" class="boxFiveBot">备案号 皖ICP备2023015659号-1，皖ICP备2023015659号-2，皖ICP备2023015659号-4，皖ICP备2023015659号-5</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "User",
  data() {
    return {
      active: 0,
    }
  },
  methods: {
    handleToggle(index) {
      this.active = index
    },
    playVideo(){
      console.log(111)
      window.open('http://www.yyyfy.com:9000/yyy/video/video1.mp4') 
    },
    goFilings(){
      window.open('https://beian.miit.gov.cn/#/Integrated/index')
    },  
  },
  props: {

  },
  computed: {

  },
};
</script>

<style lang="scss" scoped>
/* PC显示器 */
@media(min-width:690px){
  .brick{
    width: 100%;
		height: 70px;
  }
  .indexBox{
    width: 100%;
        // 背景
        .firstBox{
          width:100%;
          height:380px;
          background-image: url('https://www.yyayit.com/oss/official/pc/contactBackground.png');
          background-position: center;
          background-size:cover;
          background-repeat: no-repeat;
          overflow: hidden;
          .intitleBox{
            position: relative;
            width: 500px;
            height: 38px;
            text-align: center;
            margin: 130px auto 0;
            .titleY{
              width: 500px;
              font-size: 23px;
              text-align: center;
              position: absolute;
              margin-top:2px;
              letter-spacing: 6px;
              color: #e7e7e74d;
            }
            .titleZ{
              width: 500px;
              font-size: 26px;
              text-align: center;
              position: absolute;
              color:#fff;
            }
            .titleL{
              width: 50px;
              height: 35px;
              margin: 0 auto;
              border-bottom:3px solid #3a68f4;
            }
          }
        }
        //邮箱微信
        .emailWxBox{
          position: fixed;
          width: 100%;
          top:290px;
          text-align: center;
          cursor: pointer;
          .inWxEmail{
            width: 480px;
            height: 220px;
            padding:75px 150px 0;
            display: flex;
            justify-content: space-between;
            margin:0 auto;
            background-color: #fff;
            border-radius: 10px;
            box-shadow: 0 4px 4px 0 #eee;
            // .wxleft{
            //   .wximg{
            //     img{
            //       width: 100px;
            //       height: 100px;
            //     }
            //   }
            //   .wxname{
            //     margin-top:10px;
            //   }
            // }
            .rightEmail{
              margin: 0 auto;
                .emailimg{
                  img{
                    width: 100px;
                    height: 100px;
                  }
                }
                .emailname{
                  margin-top:10px;
                }
            }
          }
        }
        //底部
        .footBox{
          position: fixed;
          width: 100%;
          height: 50px;
          padding-top:15px;
          background-color: #2e354e;
          bottom:0;
          text-align: center;
          cursor: pointer;
          color:#fff;
          font-size: 13px;
          .boxFiveBot{
            margin-top:6px;
          }
        }
  }

}
/* 手机 */
@media(max-width:690px){
  .brick{
    width: 100%;
		height: 70px;
  }
  .indexBox{
    width: 100%;
      // 背景
      .firstBox{
        width:100%;
        height:320px;
        background-image: url('https://www.yyayit.com/oss/official/mobile/contactBackground.png');
        background-position: center;
        background-size:cover;
        background-repeat: no-repeat;
        overflow: hidden;
        // 标题
        .intitleBox{
          position: relative;
          width: 260px;
          height: 35px;
          text-align: center;
          margin: 100px auto 20px;
          .titleY{
            width: 260px;
            font-size: 20px;
            text-align: center;
            position: absolute;
            letter-spacing: 6px;
            height: 40px;
            margin-top:3px;
            color: #e7e7e74d;
          }
          .titleZ{
            width: 260px;
            font-size: 25px;
            text-align: center;
            position: absolute;
            color:#fff;
          }
          .titleL{
            width: 35px;
            height: 30px;
            margin: 0 auto;
            border-bottom:3px solid #3a68f4;
          }
        }
      }
      //邮箱微信
      .emailWxBox{
        position: fixed;
        width: 100%;
        top:260px;
        text-align: center;
        .inWxEmail{
          width: 95%;
          height: 300px;
          justify-content: space-between;
          margin:0 auto;
          background-color: #fff;
          border-radius: 10px;
          padding-top:30px;
          box-shadow: 0 4px 4px 0 #eee;
          .wxleft{
            .wximg{
              img{
                width: 90px;
                height: 90px;
              }
            }
            .wxname{
              margin-top:10px;
            }
          }
          .rightEmail{
              .emailimg{
                margin-top:60px;
                img{
                  width: 90px;
                  height: 90px;
                }
              }
              .emailname{
                margin-top:10px;
              }
          }
        }
      }
      //底部
      .footBox{
        position: fixed;
        width: 100%;
        height: 70px;
        padding-top:15px;
        background-color: #2e354e;
        bottom:0;
        text-align: center;
        cursor: pointer;
        color:#fff;
        font-size: 13px;
        .boxFiveBot{
          margin-top:6px;
        }
      }
  }
}
</style>
