<template>
  <div class="introduceBox">
  </div>
</template>
<script>
	export default {
		name: "Introduce",
		data() {
			return {
				active: 0,
			}
		},
		onLoad() {

		},
		methods: {
			goFilings(){
				window.open('https://beian.miit.gov.cn/#/Integrated/index')
			},  
		}
	}
</script>

<style lang="scss" scoped>
/* PC显示器 */
@media(min-width:690px){
		.introduceBox{
			width: 100%;
			height: 580px;
			position: relative;
			margin: 0 auto;
			top: 0;
			background: url('../assets/img/pc/productPicture1.png');
			background-repeat: no-repeat;
			background-size:cover ;
			opacity: 1;
        	animation: changeBg 35s linear infinite;
			@keyframes changeBg{
				0%{
					background-image: url('../assets/img/pc/productPicture1.png');
					height: 580px;
				}
				15%{
					background-image: url('../assets/img/pc/productPicture1.png');
					height: 580px;
				}
				20%{
					background-image: url('../assets/img/pc/productPicture2.png');
					height: 580px;
				}
				35%{
					background-image: url('../assets/img/pc/productPicture2.png');
					height: 580px;
				}
				40%{
					background-image: url('../assets/img/pc/productPicture3.png');
					height: 580px;
				}
				55%{
					background-image: url('../assets/img/pc/productPicture3.png');
					height: 580px;
				}
				60%{
					background-image: url('../assets/img/pc/productPicture4.png');
					height: 580px;
				}
				75%{
					background-image: url('../assets/img/pc/productPicture4.png');
					height: 580px;
				}
				80%{
					background-image: url('../assets/img/pc/productPicture5.png');
					height: 580px;
				}
				95%{
					background-image: url('../assets/img/pc/productPicture5.png');
					height: 580px;
				}
			}
		}
}
/* 手机 */
@media(max-width:690px){
		.introduceBox{
			width: 100%;
			height: 390px;
			position: relative;
			margin: 0 auto;
			top: 0;
			background: url('../assets/img/mobile/productPicture1.png');
			background-repeat: no-repeat;
			background-size:cover ;
			opacity: 1;
        	animation: changeBg 35s linear infinite;
			@keyframes changeBg{
				0%{
					background-image: url('../assets/img/mobile/productPicture1.png');
					height: 390px;
				}
				15%{
					background-image: url('../assets/img/mobile/productPicture1.png');
					height: 390px;
				}
				20%{
					background-image: url('../assets/img/mobile/productPicture2.png');
					height: 390px;
				}
				35%{
					background-image: url('../assets/img/mobile/productPicture2.png');
					height: 390px;
				}
				40%{
					background-image: url('../assets/img/mobile/productPicture3.png');
					height: 390px;
				}
				55%{
					background-image: url('../assets/img/mobile/productPicture3.png');
					height: 390px;
				}
				60%{
					background-image: url('../assets/img/mobile/productPicture4.png');
					height: 390px;
				}
				75%{
					background-image: url('../assets/img/mobile/productPicture4.png');
					height: 390px;
				}
				80%{
					background-image: url('../assets/img/mobile/productPicture5.png');
					height: 390px;
				}
				95%{
					background-image: url('../assets/img/mobile/productPicture5.png');
					height: 390px;
				}
			}
		}
}

</style>
