<template>
  <div class="indexBox">
    <div class="brick"></div>
    <!-- 背景 -->
    <div class="firstBox">
      <div @click="judgeClient()" class="loader">立即下载</div>
    </div>
    <!-- 标题 -->
    <div class="titleBox">
      <div class="intitleBox">
        <div class="titleY">FUNCTION INTRODUCTION</div>
        <div class="titleZ">功能介绍</div>
        <div class="titleL"></div>
      </div>
    </div>
    <!-- tab切换 -->
    <div class="sContentBox">
      <div class="insContentBox">
        <div @click="handleToggle(0)" class="itemContent">
          <div :class="[active == 0 ? 'noNameBox' : 'nameBox']" >
            <div class="first">
              <img v-if="active == 0" src="https://www.yyayit.com/oss/official/mobile/sameVoice1.png" alt="">
              <img v-else src="https://www.yyayit.com/oss/official/mobile/sameVoice3.png" alt="">
            </div>
            <div class="name">同声传译</div>
          </div>
          <div v-if="active == 0" class="itemImgFive">
            <img src="https://www.yyayit.com/oss/official/mobile/Rectangle.png" alt="">
          </div>
        </div>
        <div @click="handleToggle(1)" class="itemContent">
          <div :class="[active == 1 ? 'noNameBox' : 'nameBox']">
            <div class="first">
              <img v-if="active == 1" src="https://www.yyayit.com/oss/official/mobile/alternate1.png" alt="">
              <img v-else src="https://www.yyayit.com/oss/official/mobile/alternate2.png" alt="">
            </div>
            <div class="name">交替翻译</div>
          </div>
          <div v-if="active == 1" class="itemImgFive">
            <img src="https://www.yyayit.com/oss/official/mobile/Rectangle.png" alt="">
          </div>
        </div>
        <div @click="handleToggle(2)" class="itemContent">
          <div :class="[active == 2 ? 'noNameBox' : 'nameBox']">
            <div class="first">
              <img v-if="active == 2" src="https://www.yyayit.com/oss/official/mobile/window1.png" alt="">
              <img v-else src="https://www.yyayit.com/oss/official/mobile/window3.png" alt="">
            </div>
            <div class="name">浮窗（同传）</div>
          </div>
          <div v-if="active == 2" class="itemImgFive">
            <img src="https://www.yyayit.com/oss/official/mobile/Rectangle.png" alt="">
          </div>
        </div>
        <div @click="handleToggle(3)" class="itemContent">
          <div :class="[active == 3 ? 'noNameBox' : 'nameBox']">
            <div class="first">
              <img v-if="active == 3" src="https://www.yyayit.com/oss/official/mobile/text1.png" alt="">
              <img v-else src="https://www.yyayit.com/oss/official/mobile/text3.png" alt="">
            </div>
            <div class="name">文档翻译</div>
          </div>
          <div v-if="active == 3" class="itemImgFive">
            <img src="https://www.yyayit.com/oss/official/mobile/Rectangle.png" alt="">
          </div>
        </div>
        <div @click="handleToggle(4)" class="itemContent">
          <div :class="[active == 4 ? 'noNameBox' : 'nameBox']">
            <div class="first">
              <img v-if="active == 4" src="https://www.yyayit.com/oss/official/mobile/takePhoto1.png" alt="">
              <img v-else src="https://www.yyayit.com/oss/official/mobile/takePhoto3.png" alt="">
            </div>
            <div class="name">拍照翻译</div>
          </div>
          <div v-if="active == 4" class="itemImgFive">
            <img src="https://www.yyayit.com/oss/official/mobile/Rectangle.png" alt="">
          </div>
        </div>
      </div>
    </div>
    <!-- tab切换内容 -->
    <div class="firstContent">
      <div v-if="active == 0" class="tabBoxc">
        <div class="cRight">
          <div class="cTitle">同声传译</div>
          <div class="cContent">将源语言的语音实时翻译成目标语言的语音，双语（源语言与目标语言）文本显示，同传语音播报（使用普通蓝牙耳机就可以收听目标语言的语音播报）。</div>
        </div>
        <div class="cLeft">
          <img src="https://www.yyayit.com/oss/official/mobile/photo1.png" alt="">
        </div>
      </div>
      <div v-if="active == 1" class="tabBoxc">
        <div class="cLeft">
          <img src="https://www.yyayit.com/oss/official/mobile/photo2.png" alt="">
        </div>
        <div class="cRight">
          <div class="cTitle">交替翻译</div>
          <div class="cContent">两种语言间实时语音交替翻译，双语文本显示，播报目标语言的语音。</div>
        </div>
      </div>
      <div v-if="active == 2" class="tabBoxc">
        <div class="cRight">
          <div class="cTitle">浮窗（同传）</div>
          <div class="cContent">将源语言的语音实时翻译成目标语言的语音，双语（源语言与目标语言）文本显示，同传语音播报（使用普通蓝牙耳机就可以收听目标语言的语音播报）。</div>
        </div>
        <div class="cLeft">
          <img src="https://www.yyayit.com/oss/official/mobile/photo3.png" alt="">
        </div>
      </div>
      <div v-if="active == 3" class="tabBoxc">
        <div class="cLeft">
          <img src="https://www.yyayit.com/oss/official/mobile/photo4.png" alt="">
        </div>
        <div class="cRight">
          <div class="cTitle">文档翻译</div>
          <div class="cContent">支持Word/PPT/Excel/PDF等多种文档格式和200+语种互译，满足常规文档翻译需求。高度还原原文档样式。</div>
        </div>
      </div>
      <div v-if="active == 4" class="tabBoxc">
        <div class="cRight">
          <div class="cTitle">拍照翻译</div>
          <div class="cContent">可以对图片中的文本内容进行文字识别、语种自动检测并翻译成目标语言，支持译文实景回填。</div>
        </div>
        <div class="cLeft">
          <img src="https://www.yyayit.com/oss/official/mobile/photo5.png" alt="">
        </div>
      </div>
    </div>
    <!-- 手机功能介绍 -->
    <div class="gnMobileBox">
      <div class="useItem">
        <div class="nameIconBox">
          <div class="icon"><img src="https://www.yyayit.com/oss/official/mobile/sameVoice3.png" alt=""></div>
          <div class="name">同声传译</div>
        </div>
        <div class="content">将源语言的语音实时翻译成目标语言的语音，双语（源语言与目标语言）文本显示，同传语音播报（使用普通蓝牙耳机就可以收听目标语言的语音播报）。</div>
        <div class="bj">
          <div class="mobileBj"><img src="https://www.yyayit.com/oss/official/mobile/photo1.png" alt=""></div>
        </div>
      </div>
      <div class="useItem">
        <div class="nameIconBox">
          <div class="icon"><img src="https://www.yyayit.com/oss/official/mobile/alternate2.png" alt=""></div>
          <div class="name">交替翻译</div>
        </div>
        <div class="content">两种语言间实时语音交替翻译，双语文本显示，播报目标语言的语音。</div>
        <div class="bj">
          <div class="mobileBj"><img src="https://www.yyayit.com/oss/official/mobile/photo2.png" alt=""></div>
        </div>
      </div>
      <div class="useItem">
        <div class="nameIconBox">
          <div class="icon"><img src="https://www.yyayit.com/oss/official/mobile/window3.png" alt=""></div>
          <div class="name">浮窗（同传）</div>
        </div>
        <div class="content">将源语言的语音实时翻译成目标语言的语音，双语（源语言与目标语言）文本显示，同传语音播报（使用普通蓝牙耳机就可以收听目标语言的语音播报）。</div>
        <div class="bj">
          <div class="mobileBj"><img src="https://www.yyayit.com/oss/official/mobile/photo3.png" alt=""></div>
        </div>
      </div>
      <div class="useItem">
        <div class="nameIconBox">
          <div class="icon"><img src="https://www.yyayit.com/oss/official/mobile/text3.png" alt=""></div>
          <div class="name">文档翻译</div>
        </div>
        <div class="content">支持Word/PPT/Excel/PDF等多种文档格式和200+语种互译，满足常规文档翻译需求。高度还原原文档样式。</div>
        <div class="bj">
          <div class="mobileBj"><img src="https://www.yyayit.com/oss/official/mobile/photo4.png" alt=""></div>
        </div>
      </div>
      <div class="useItem">
        <div class="nameIconBox">
          <div class="icon"><img src="https://www.yyayit.com/oss/official/mobile/takePhoto3.png" alt=""></div>
          <div class="name">拍照翻译</div>
        </div>
        <div class="content">可以对图片中的文本内容进行文字识别、语种自动检测并翻译成目标语言，支持译文实景回填。</div>
        <div class="bj">
          <div class="mobileBj"><img src="https://www.yyayit.com/oss/official/mobile/photo5.png" alt=""></div>
        </div>
      </div>
    </div>
    <!-- 标题 -->
    <div class="titleBox">
      <div class="intitleBox">
        <div class="titleY">USAGE SCENARIO</div>
        <div class="titleZ">使用场景</div>
        <div class="titleL"></div>
      </div>
    </div>
    <!-- 使用场景 -->
    <div class="sceneBox">
      <div class="describeScene">
        <div class="brick"></div>
        <div class="describeContent">
          <div class="indescribeContent">
            <div class="title">留学生线下听课</div>
            <div class="line">
              <img src="https://www.yyayit.com/oss/official/pc/gradient.png" alt="">
            </div>
            <div class="content">对于那些刚刚踏上留学之路的小伙伴们，克服语言障碍是个不小的挑战。面对国外导师的全外文授课，听得云里雾里。开启同声传译功能，戴上蓝牙耳机，即时翻译即时播报，双语字幕显示，自动保存，课后复习也方便。</div>
          </div>
        </div>
      </div>
      <div class="imgScene">
        <div class="imgBox">
          <img src="https://www.yyayit.com/oss/official/pc/figure1.png" alt="">
        </div>
      </div>
    </div>
    <div class="sceneBox">
      <div class="imgScene">
        <div class="imgBox">
          <img src="https://www.yyayit.com/oss/official/pc/figure2.png" alt="">
        </div>
      </div>
      <div class="describeScene">
        <div style="height: 40px;background-color: #fff;" ></div>
        <div class="describeContent">
          <div class="rIndescribeContent">
            <div class="title">听网课（外语课程）</div>
            <div class="line">
              <img src="https://www.yyayit.com/oss/official/pc/gradient.png" alt="">
            </div>
            <div class="content">不仅可以使用同声传译功能去翻译另一台设备上的网课视频，也可以用浮窗（同传）翻译本机上的网课视频。</div>
          </div>
        </div>
      </div>
    </div>
    <div class="sceneBox">
      <div class="describeScene">
        <div class="brick"></div>
        <div class="describeContent">
          <div class="indescribeContent">
            <div class="title">商务会谈</div>
            <div class="line">
              <img src="https://www.yyayit.com/oss/official/pc/gradient.png" alt="">
            </div>
            <div class="content">在和外国合作伙伴会谈时，可用交替翻译功能交流。也可以每人一部手机，分别打开同声传译功能交流。</div>
          </div>
        </div>
      </div>
      <div class="imgScene">
        <div class="imgBox">
          <img src="https://www.yyayit.com/oss/official/pc/image3.png" alt="">
        </div>
      </div>
    </div>
    <div class="sceneBox">
      <div class="imgScene">
        <div class="imgBox">
          <img src="https://www.yyayit.com/oss/official/pc/figure4.png" alt="">
        </div>
      </div>
      <div class="describeScene">
        <div style="height: 40px;background-color: #fff;" ></div>
        <div class="describeContent">
          <div class="rIndescribeContent">
            <div class="title">旅游</div>
            <div class="line">
              <img src="https://www.yyayit.com/oss/official/pc/gradient.png" alt="">
            </div>
            <div class="content">将源语言的语音实时翻译成目标语言的语音，双语（源语言与目标语言）文本显示，同传语音播报（使用普通蓝牙耳机就可以收听目标语言的语音播报）。</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 手机使用场景 -->
    <div class="mSceneBox">
      <div class="describeScene">
        <div class="describeContent">
          <div class="rIndescribeContent">
            <div class="title">留学生线下听课</div>
            <div class="line">
              <img src="https://www.yyayit.com/oss/official/pc/gradient.png" alt="">
            </div>
            <div class="content">将源语言的语音实时翻译成目标语言的语音，双语（源语言与目标语言）文本显示，同传语音播报（使用普通蓝牙耳机就可以收听目标语言的语音播报）。</div>
          </div>
        </div>
      </div>
      <div class="imgScene">
        <div class="imgBox">
          <img src="https://www.yyayit.com/oss/official/mobile/figure1.png" alt="">
        </div>
      </div>
    </div>
    <div class="mSceneBox">
      <div class="imgScene">
        <div class="imgBox">
          <img src="https://www.yyayit.com/oss/official/mobile/figure2.png" alt="">
        </div>
      </div>
      <div class="describeScene">
        <div class="describeContent">
          <div class="rIndescribeContent">
            <div class="title">听网课（外语课程）</div>
            <div class="line">
              <img src="https://www.yyayit.com/oss/official/pc/gradient.png" alt="">
            </div>
            <div class="content">不仅可以使用同声传译功能去翻译另一台设备上的网课视频，也可以用浮窗（同传）翻译本机上的网课视频。</div>
          </div>
        </div>
      </div>
    </div>
    <div class="mSceneBox">
      <div class="describeScene">
        <div class="describeContent">
          <div class="rIndescribeContent">
            <div class="title">商务会谈</div>
            <div class="line">
              <img src="https://www.yyayit.com/oss/official/pc/gradient.png" alt="">
            </div>
            <div class="content">在和外国合作伙伴会谈时，可用交替翻译功能交流。也可以每人一部手机，分别打开同声传译功能交流。</div>
          </div>
        </div>
      </div>
      <div class="imgScene">
        <div class="imgBox">
          <img src="https://www.yyayit.com/oss/official/mobile/figure4.png" alt="">
        </div>
      </div>
    </div>
    <div class="mSceneBox">
      <div class="imgScene">
        <div class="imgBox">
          <img src="https://www.yyayit.com/oss/official/mobile/image3.png" alt="">
        </div>
      </div>
      <div class="describeScene">
        <div class="describeContent">
          <div class="rIndescribeContent">
            <div class="title">旅游</div>
            <div class="line">
              <img src="https://www.yyayit.com/oss/official/pc/gradient.png" alt="">
            </div>
            <div class="content">将源语言的语音实时翻译成目标语言的语音，双语（源语言与目标语言）文本显示，同传语音播报（使用普通蓝牙耳机就可以收听目标语言的语音播报）。</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 视频演示 -->
    <div class="videoBox">
      <div class="otherbtitleBox">
        <div class="intitleBox">
          <div class="titleY">DEMO VIDEO</div>
          <div class="titleZ">演示视频</div>
          <div class="titleL"></div>
        </div>
      </div>
      <div class="videoContent">
        <video controls>
          <source src="http://www.yyyfy.com:9000/yyy/video/video1.mp4">
        </video>
      </div>
    </div>
    <!-- 地图下载 -->
    <div class="mapBox">
      <div class="motherbox">
				<div class="name">现在下载即享受免费试用</div>
				<div @click="judgeClient()" class="loader">立即下载</div>
			</div>
    </div>
    <div class="footBox">
      <div>版权所有安徽译呀译信息科技有限公司，保留一切权力</div>
      <div @click="goFilings()" class="boxFiveBot">备案号 皖ICP备2023015659号-1，皖ICP备2023015659号-2，皖ICP备2023015659号-4，皖ICP备2023015659号-5</div>
    </div>
    <el-dialog
		title=""
		:visible.sync="dialogVisible"
		width="700px"
		>
    <div class="loaderBox">
      <div class="loaderIos">
        <div class="name">IOS下载</div>
        <div>
          <img src="../assets/img/pc/ios.png" alt="">
        </div>
      </div>
      <div class="loaderA">
        <div class="name">安卓下载</div>
        <div>
          <img src="../assets/img/pc/anz.png" alt="">
        </div>
      </div>
    </div>
    <div class="loaderDescribe">国内应用市场下载：软译翻译海外谷歌，亚马逊，appstore下载: RyTranslator。</div>
		<span slot="footer" class="dialog-footer">
			<el-button type="primary" @click="dialogVisible = false">确 定</el-button>
		</span>
		</el-dialog>
  </div>
</template>

<script>
export default {
  name: "Index",
  data() {
    return {
      active: 0,
      dialogVisible:false,
    }
  },
  methods: {
    handleToggle(index) {
      this.active = index
    },
    playVideo(){
      window.open('http://www.yyyfy.com:9000/yyy/video/video1.mp4') 
    },
    goFilings(){
      window.open('https://beian.miit.gov.cn/#/Integrated/index')
    },
    judgeClient() {
      let u = navigator.userAgent;
      let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1;   //判断是否是 android终端
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);     //判断是否是 iOS终端
      if(isAndroid){
        window.open('http://produce.yyyfy.com:9000/yyy/version/rytranslator.apk')
        return 'Android';
      }else if(isIOS){
        window.open('https://apps.apple.com/us/app/%E8%BD%AF%E8%AF%91%E7%BF%BB%E8%AF%91/id6449191157')
        // alert("苹果")
        return 'IOS';
      }else{
        // window.open('https://apps.apple.com/us/app/%E8%BD%AF%E8%AF%91%E7%BF%BB%E8%AF%91/id6449191157')
        this.dialogVisible = true
        return 'PC';
      }
    },
  },
  props: {

  },
  computed: {

  },
};
</script>

<style lang="scss" scoped>
/* PC显示器 */
@media(min-width:690px){
.indexBox{
  width: 100%;
      .brick{
        width: 100%;
        height: 70px;
      }
      // 背景
			.firstBox{
				width:100%;
				height:400px;
				background-image: url('https://www.yyayit.com/oss/official/pc/homePageBanner.png');
				background-position: center;
				background-size:cover;
				background-repeat: no-repeat;
				overflow: hidden;
				.loader{
					width: 170px;
					height: 45px;
					line-height: 45px;
					color:#fff;
					border-radius: 25px;
					text-align: center;
					margin: 280px auto;
          cursor: pointer;
					background-color: #2f65f5;
				}
			}
      //标题
			.titleBox{
				width: 100%;
				height:38px;
				margin:50px auto 50px ;
				.intitleBox{
					position: relative;
					width: 500px;
					height: 38px;
					text-align: center;
					margin: 0 auto;
					.titleY{
						width: 500px;
						font-size: 23px;
						text-align: center;
						position: absolute;
            margin-top:2px;
						letter-spacing: 6px;
						color: #E7E7E7;
					}
					.titleZ{
						width: 500px;
						font-size: 26px;
						text-align: center;
						position: absolute;
						color:#051735;
					}
					.titleL{
						width: 50px;
						height: 35px;
						margin: 0 auto;
						border-bottom:3px solid #3a68f4;
					}
				}
			}
      //tab切换
			.sContentBox{
				width: 100%;
				height: 90px;
				.insContentBox{
					display: flex;
					width: 790px;
					height: 90px;
					background-color: #f8fcff;
					margin: 0 auto;
					.itemContent{
						width: 160px;
						height: 90px;
							.nameBox{
								width: 160px;
								height: 80px;
								cursor: pointer;
								background-color: #fff;
								.first{
									width: 30px;
									height: 35px;
									margin:0 auto;
									padding-top:12px;
									img{
										width: 30px;
										height: 26px;
									}
								}
								.name{
									width: 160px;
									height: 40px;
									margin-top:8px;
									text-align: center;
									font-size: 16px;
									color: #B2B2B2;
								}

							}
							.noNameBox{
								width: 160px;
								height: 80px;
								cursor: pointer;
								background-color: #2f65f5;
								.first{
									width: 30px;
									height: 35px;
									margin:0 auto;
									padding-top:12px;
									img{
										width: 30px;
										height: 28px;
									}
								}
								.name{
									width: 160px;
									height: 40px;
									margin-top:8px;
									text-align: center;
									font-size: 16px;
									color: #fff;
								}
							}
							.itemImgFive{
								width: 160px;
								height: 10px;
								text-align: center;
								img{
									width: 20px;
									height: 15px;
								}
							}
					}
				}

			}
      //tab切换内容
			.firstContent{
				width: 820px;
				height: 260px;
				margin: 8px auto 0;
				background-image: url('https://www.yyayit.com/oss/official/mobile/bottomFrame.png');
				background-position: center;
				background-size:cover;
				background-repeat: no-repeat;
				.tabBoxc{
					width: 790px;
					display: flex;
					.cRight{
						width: 300px;
						margin-top:60px;
						padding-left:50px;
						.cTitle{
							font-weight: 800;
						}
						.cContent{
							margin-top:15px;
							font-size: 13px;
							color:#666666;
						}
					}
					.cLeft{
						width: 450px;
						img{
							width: 450px;
						}
					}
				}
			}
      .gnMobileBox{
        display: none;
      }
      .sceneBox{
        width: 100%;
        margin-bottom:80px;
        display: flex;
        .describeScene{
          width: 52%;
          background-color: #B2B2B2;
          .brick{
            height: 40px;
            background-color: #fff;
          }
          .describeContent{
            height: 100%;
            position: relative;
            background-color: #f6fafd;
            // 左边
            .indescribeContent{
              position: absolute;
              top:50px;
              right:60px;
              width: 300px;
              height: 120px;
              font-size: 15px;
              color:#666666;
              .title{
                font-size: 18px;
                color:#333;
                font-weight: 700;
              }
              .line{
                position: absolute;
                top:10px;
                img{
                  width: 130px;
                }
              }
              .content{
                margin-top:13px;
              }
            }
            // 右边
            .rIndescribeContent{
              position: absolute;
              top:50px;
              left:60px;
              width: 300px;
              height: 120px;
              font-size: 15px;
              color:#666666;
              .title{
                font-size: 18px;
                color:#333;
                font-weight: 700;
              }
              .line{
                position: absolute;
                top:10px;
                img{
                  width: 130px;
                }
              }
              .content{
                margin-top:13px;
              }
            }
          }
        }
        .imgScene{
          width: 48%;
          .imgBox{
            img {
              width: 100%;
            }
          }

        }
      }
      .mSceneBox{
        display: none;
      }
      // 视频
      .videoBox{
        width: 100%;
        height: 520px;
        padding-top:40px; 
        background-image: url('https://www.yyayit.com/oss/official/pc/videoBackground.png');
				background-position: center;
				background-size:cover;
				background-repeat: no-repeat;
        //标题
        .otherbtitleBox{
          width: 100%;
          height:38px;
          margin:0 auto;
          .intitleBox{
            position: relative;
            width: 500px;
            height: 38px;
            text-align: center;
            margin: 0 auto;
            .titleY{
              width: 500px;
              font-size: 23px;
              text-align: center;
              position: absolute;
              margin-top:2px;
              letter-spacing: 6px;
              color: #E7E7E7;
              opacity: 0.2;
            }
            .titleZ{
              width: 500px;
              font-size: 26px;
              text-align: center;
              position: absolute;
              color:#fff;
            }
            .titleL{
              width: 50px;
              height: 35px;
              margin: 0 auto;
              border-bottom:3px solid #fff;
            }
          }
        }
        .videoContent{
          width: 700px;
          height: 380px;
          margin: 30px auto 0;
          padding:5px 0 0;
          border-radius: 10px;
          background-color: #fff;
          video{
            width: 700px;
            height: 380px;
            border-radius: 10px;
          }
        }
      }
      //地图下载
			.mapBox{
				width:100%;
				height:280px;
				background-image: url('https://www.yyayit.com/oss/official/pc/worldMap.png');
				background-position: center;
				background-size:cover;
				background-repeat: no-repeat;
				overflow: hidden;
			  .motherbox{
					width: 400px;
					height: 15px;
					color:#fff;
					border-radius: 8px;
					text-align: center;
					margin: 140px auto;
					color:#000;
					.name{
						width: 350px;
						height: 15px;
						color:#fff;
						border-radius: 8px;
						text-align: center;
						margin: 20px auto;
						color:#000;
						font-weight: 800;
						font-size: 20px;
					}
					.loader{
						width: 130px;
						height: 40px;
						line-height: 40px;
						color:#fff;
						border-radius: 8px;
						text-align: center;
            cursor: pointer;
						margin: 0 auto;
						background-color: #2f65f5;
					}
				}
			}
      .loaderBox{
        width: 90%;
        display: flex;
        justify-content: space-between;
        margin:0 auto;
        .loaderIos{
          .name{
            width: 200px;
            height: 40px;
            font-size: 20px;
            font-weight: 600;
            text-align: center;
          }
          img{
            width: 200px;
            height: 200px;
          }
        }
        .loaderA{
          .name{
            width: 200px;
            height: 40px;
            font-size: 20px;
            font-weight: 600;
            text-align: center;
          }
          img{
            width: 200px;
            height: 200px;
          }
        }
      }
      .loaderDescribe{
        width: 90%;
        margin:20px auto 0;
        font-size: 16px;
      }
      //底部
      .footBox{
        width: 100%;
        height: 55px;
        padding-top:15px;
        background-color: #2e354e;
        bottom:0;
        text-align: center;
        cursor: pointer;
        color:#fff;
        font-size: 13px;
        .boxFiveBot{
          margin-top:6px;
        }
      }
}
}
/* 手机 */
@media(max-width:690px){
.indexBox{
  width: 100%;
    // 背景
    .brick{
      width: 100%;
      height: 70px;
    }
		.firstBox{
			width:100%;
			height:400px;
			background-image: url('https://www.yyayit.com/oss/official/pc/homePageBanner.png');
			background-position: center;
			background-size:cover;
			background-repeat: no-repeat;
			overflow: hidden;
			.loader{
				width: 170px;
				height: 45px;
				line-height: 45px;
				color:#fff;
				border-radius: 25px;
				text-align: center;
				margin: 280px auto;
				background-color: #2f65f5;
			}
		}
    // 标题
    .intitleBox{
      position: relative;
      width: 260px;
      height: 35px;
      text-align: center;
      margin: 30px auto 20px;
      .titleY{
        width: 260px;
        font-size: 17px;
        text-align: center;
        position: absolute;
        letter-spacing: 6px;
        height: 40px;
        margin-top:3px;
        color: #E7E7E7;
        letter-spacing: 1px;
      }
      .titleZ{
        width: 260px;
        font-size: 22px;
        text-align: center;
        position: absolute;
        font-weight: 800;
        color:#000;
      }
      .titleL{
        width: 35px;
        height: 28px;
        margin: 0 auto;
        border-bottom:3px solid #3a68f4;
      }
    }
    //tab切换
    .sContentBox{
      display: none;
    }
    //tab切换内容
    .firstContent{
      display: none;
    }
    // 手机功能介绍
    .gnMobileBox{
      width: 100%;
			.useItem{
				width: 95%;
				margin:15px auto 40px;
				.nameIconBox{
					width: 200px;
					display: flex;
					.icon{
						width: 30px;
						height: 30px;
						img{
							width: 30px;
							height: 30px;
						}
					}
					.name{
						height: 30px;
						line-height: 30px;
						margin-left:10px;
					}
				}
				.content{
					width: 100%;
					height: 60px;
					color:#666666;
					font-size: 14px;
					margin:10px 0;
				}
				.bj{
					width: 100%;
					height:200px;
					background-image: url('https://www.yyayit.com/oss/official/mobile/Group4651.png');
					background-position: center;
					background-size:cover;
					background-repeat: no-repeat;
					.mobileBj{
							width: 350px;
							height: 200px;
							margin: 0 auto;
						img{
							width: 350px;
							height: 200px;
						}
					}
				}
			}
    }
    .sceneBox{
      display: none;
    }
    .mSceneBox{
      display: flex;
      margin-bottom:30px;
        .describeScene{
          width: 48%;
          // height: 230px;
          // background-color: #B2B2B2;
          .rIndescribeContent{
            height: 100%;
            position: relative;
            background-color: #f6fafd;
            padding:30px 5px 50px 10px;
            // 左边
              .title{
                font-size: 18px;
                color:#333;
                font-weight: 700;
              }
              .line{
                position: absolute;
                top:37px;
                img{
                  width: 130px;
                }
              }
              .content{
                margin-top:13px;
                font-size: 14px;
                color:#666;
              }
          }
        }
        .imgScene{
          width: 52%;
          margin-top:45px;
          .imgBox{
            img {
              width: 100%;
            }
          }

        }
    }
    //视频
    .videoBox{
      width: 100%;
      height: 330px;
      padding-top:40px; 
      background-image: url('https://www.yyayit.com/oss/official/mobile/videoBackground.png');
      background-position: center;
      background-size:cover;
      background-repeat: no-repeat;
      // 标题
      .intitleBox{
        position: relative;
        width: 260px;
        height: 35px;
        text-align: center;
        margin: 0 auto 20px;
        .titleY{
          width: 260px;
          font-size: 17px;
          text-align: center;
          position: absolute;
          letter-spacing: 6px;
          height: 40px;
          margin-top:3px;
          color: #E7E7E7;
          letter-spacing: 2px;
          opacity: 0.3;
        }
        .titleZ{
          width: 260px;
          font-size: 22px;
          text-align: center;
          position: absolute;
          font-weight: 800;
          color:#fff;
        }
        .titleL{
          width: 35px;
          height: 28px;
          margin: 0 auto;
          border-bottom:3px solid #fff;
        }
      }
      .videoContent{
        width: 95%;
        // height: 380px;
        margin: 0 auto;
        padding:5px 0 0;
        border-radius: 10px;
        background-color: #fff;
        video{
          width: 100%;
        }
      }
    }
    //地图下载
    .mapBox{
      width:100%;
      height:280px;
      background-image: url('https://www.yyayit.com/oss/official/mobile/worldMap.png');
      background-position: center;
      background-size:cover;
      background-repeat: no-repeat;
      overflow: hidden;
      .motherbox{
        width: 400px;
        height: 15px;
        color:#fff;
        border-radius: 8px;
        text-align: center;
        margin: 140px auto;
        color:#000;
        .name{
          width: 350px;
          height: 15px;
          color:#fff;
          border-radius: 8px;
          text-align: center;
          margin: 20px auto;
          color:#000;
          font-weight: 800;
          font-size: 20px;
        }
        .loader{
          width: 130px;
          height: 40px;
          line-height: 40px;
          color:#fff;
          border-radius: 8px;
          text-align: center;
          margin: 0 auto;
          background-color: #2f65f5;
        }
      }
    }
    .loaderBox{
      width: 90%;
      display: flex;
      justify-content: space-between;
      margin:0 auto;
      .loaderIos{
        .name{
          width: 200px;
          height: 40px;
          font-size: 20px;
          font-weight: 600;
          text-align: center;
        }
        img{
          width: 200px;
          height: 200px;
        }
      }
      .loaderA{
        .name{
          width: 200px;
          height: 40px;
          font-size: 20px;
          font-weight: 600;
          text-align: center;
        }
        img{
          width: 200px;
          height: 200px;
        }
      }
    }
    .loaderDescribe{
      width: 90%;
      margin:20px auto 0;
      font-size: 16px;
    }
    //底部
    .footBox{
      width: 100%;
      height: 70px;
      padding-top:15px;
      background-color: #2e354e;
      bottom:0;
      text-align: center;
      cursor: pointer;
      color:#fff;
      font-size: 13px;
      .boxFiveBot{
        margin-top:6px;
      }
    }
}
}
</style>
