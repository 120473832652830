<template>
	<div class="Box">
		<div class="NavigationBar">
			<div class="inBar">
				<div class="barLeft">
					<img src="https://www.yyayit.com/oss/official/pc/logo.png" alt="">
				</div>
				<div class="barRight">
					<div class="noMobileBox">
						<div class="barRightL">
							<div class="selectContentBox">
								<div @click="changeEvent(index)" v-for="(item, index) in list" :key="item.surveyOptionId" :class="[current == index ? 'active-item' : 'item']">
									<div>{{ item.name }}</div>
									<div v-if="current == index" class="nameLineBox">
										<div class="line"></div>
									</div>
								</div>
							</div>
						</div>
						<div @click="judgeClient()" class="loader">下载手机app</div>
					</div>
					<div @click="openWindow" class="mobileBox">
						<img src="https://www.yyayit.com/oss/official/mobile/bullet.png" alt="">
					</div>
				</div>
			</div>
		</div>
    <!-- <div class="brick"></div> -->
		<div v-show="current == 0" >
			<Index></Index>
		</div>
		<div v-show="current == 1">
      <Introduce></Introduce>
      <Swiper></Swiper>
		</div>
		<div v-show="current == 2">
        <User></User>
		</div>
		<!-- 弹窗 -->
		<el-drawer
		:visible.sync="drawer"
		:direction="direction"
		size="48%"
		>
		<div class="mSelectBox">
			<div @click="changeEvent(index)" v-for="(item, index) in list" :key="item.surveyOptionId" :class="[current == index ? 'active-item' : 'item']">
				<div>{{ item.name }}</div>
			</div>
		</div>
		<div @click="judgeClient(index)" class="mLoader">下载手机app</div>
		</el-drawer>
		<el-dialog
			title=""
			:visible.sync="dialogVisible"
			width="700px"
			>
		<div class="loaderBox">
		<div class="loaderIos">
			<div class="name">IOS下载</div>
			<div>
			<img src="../assets/img/pc/ios.png" alt="">
			</div>
		</div>
		<div class="loaderA">
			<div class="name">安卓下载</div>
			<div>
			<img src="../assets/img/pc/anz.png" alt="">
			</div>
		</div>
		</div>
		<div class="loaderDescribe">国内应用市场下载：软译翻译海外谷歌，亚马逊，appstore下载: RyTranslator。</div>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="dialogVisible = false">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
  import Index from '@/components/Index'
  import Introduce from '@/components/Introduce'
  import Swiper from '@/components/Swiper'
  import User from '@/components/User'
	export default {
		data() {
			return {
				list: [{
					name: '首页',
				}, {
					name: '产品介绍'
				}, {
					name: '联系我们',
				}],
				current: 0,
				drawer: false,
				direction: 'rtl',
				dialogVisible:false,
			}
		},

    components: {
        Index,
        Swiper,
        Introduce,
        User
    },

		onLoad() {

		},
		methods: {
			changeEvent(index) {
				this.current = index;
				this.drawer = false
			},
			openWindow(){
        this.drawer = true
			},
			goFilings(){
				window.open('https://beian.miit.gov.cn/#/Integrated/index')
			},
			judgeClient() {
			let u = navigator.userAgent;
			let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1;   //判断是否是 android终端
			let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);     //判断是否是 iOS终端
			if(isAndroid){
				window.open('http://produce.yyyfy.com:9000/yyy/version/rytranslator.apk')
				return 'Android';
			}else if(isIOS){
				window.open('https://apps.apple.com/us/app/%E8%BD%AF%E8%AF%91%E7%BF%BB%E8%AF%91/id6449191157')
				// alert("苹果")
				return 'IOS';
			}else{
				// window.open('https://apps.apple.com/us/app/%E8%BD%AF%E8%AF%91%E7%BF%BB%E8%AF%91/id6449191157')
				this.dialogVisible = true
				return 'PC';
			}
			},
		}
	}
</script>

<style lang="scss" scoped>
/* PC显示器 */
@media(min-width:690px){
	.NavigationBar{
		width: 100%;
		height: 70px;
		background-color: #fff;
		box-shadow: 0 1px 1px 0 #eee;
    z-index: 999;
    position: fixed;
		.inBar{
			width: 900px;
			height: 70px;
			margin:0 auto;
			display: flex;
      justify-content: space-between;

			.barLeft{
				width: 50%;
				height: 70px;
				img{
					width: 130px;
					height: 45px;
					margin-top:16px;
				}
			}
			.barRight{
				width: 50%;
				height: 70px;
				.mobileBox{
					display: none;
				}
				.noMobileBox{
					display: flex;
					height: 70px;
					justify-content: space-between;
					.barRightL{
						width: 80%;
						height: 70px;
						font-size: 0.1px;
							.selectContentBox{
								height: 40px;
								width: 100%;
								cursor: pointer;
								font-size: 15px;
								display: flex;
								color:#333;
                				margin-top:26px;
								.item{
									height: 40px;
									text-align: center;
									width: 100px;
								}
								.active-item{
							    height: 40px;
									text-align: center;
									width: 100px;
									color:#3A68F4;
								}
								.nameLineBox{
									width: 100px;
									margin-top:5px;
									.line{
										width: 25px;
										height: 2.5px;
										margin:0 auto;
										background-color: #3A68F4;
									}
								}

							}
					}
					.loader{
						width:120px;
						height: 37px;
						line-height: 37px;
						text-align: center;
						color:#fff;
						border-radius: 5px;
						margin-top:17px;
            font-size: 14px;
            cursor: pointer;
						background-color: #2f65f5;
					}
				}
			}
		}
	}
	.brick{
		width: 100%;
			height: 70px;
	}
	.loaderBox{
        width: 90%;
        display: flex;
        justify-content: space-between;
        margin:0 auto;
        .loaderIos{
          .name{
            width: 200px;
            height: 40px;
            font-size: 20px;
            font-weight: 600;
            text-align: center;
          }
          img{
            width: 200px;
            height: 200px;
          }
        }
        .loaderA{
          .name{
            width: 200px;
            height: 40px;
            font-size: 20px;
            font-weight: 600;
            text-align: center;
          }
          img{
            width: 200px;
            height: 200px;
          }
        }
    }
	.loaderDescribe{
      width: 90%;
      margin:20px auto 0;
      font-size: 16px;
    }
}
/* 手机 */
@media(max-width:690px){
	.NavigationBar{
		width: 100%;
		height: 70px;
		background-color: #fff;
    z-index: 999;
		box-shadow: 0 1px 1px 0 #eee;
    position: fixed;
		.inBar{
			width: 95%;
			height: 70px;
			margin:0 auto;
			display: flex;
      justify-content: space-between;

			.barLeft{
				width: 92%;
				height: 70px;
				img{
					width: 120px;
					height: 41px;
					margin-top:16px;
				}
			}
			.barRight{
				width: 25px;
				height: 70px;
        // background-color: pink;
		    .noMobileBox{
					display: none;
				}
        .mobileBox{
					img{
						margin:25px auto 0;
						width: 25px;
						height: 20px;
					}
				}
			}
		}
	}
	.mSelectBox{
		width: 80%;
		// cursor: pointer;
		// margin:0 0 0 20px; 
    margin-left:20px;
		font-size: 18px;
		font-weight: 700;
		color:#000;
		.item{
			height: 50px;
		}
		.active-item{
			height: 50px;
			color:#3A68F4;
		}

	}
	.mLoader{
		width: 120px;
		height: 40px;
		line-height: 40px;
		color:#fff;
		// margin:0 0 0 0.5px;
     margin-left:20px;
		text-align: center;
		border-radius: 5px;
		font-size: 13px;
		background-color: #3A68F4;
	}
  .brick{
    width: 100%;
		height: 70px;
  }
	.loaderBox{
		width: 90%;
		display: flex;
		justify-content: space-between;
		margin:0 auto;
		.loaderIos{
			.name{
			width: 200px;
			height: 40px;
			font-size: 20px;
			font-weight: 600;
			text-align: center;
			}
			img{
			width: 200px;
			height: 200px;
			}
		}
		.loaderA{
			.name{
			width: 200px;
			height: 40px;
			font-size: 20px;
			font-weight: 600;
			text-align: center;
			}
			img{
			width: 200px;
			height: 200px;
			}
		}
	}
	.loaderDescribe{
		width: 90%;
		margin:20px auto 0;
		font-size: 16px;
	}
}
</style>
